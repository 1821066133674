<template>
  <admin-content
    title="Families"
    :headers="headers"
    :items="families.items"
    :num-pages="families.pages"
    :page="families.page"
    :search="families.search"
    @page="goTo"
    @search="onSearch"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="deleteItem"
    hide-filters
  >

    <edit-dialog
      v-model="dialog"
      :title="dialogTitle"
      @click:save="submit"
    >
      <v-form
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <textfield
          v-model="model.family"
          :rules="rules.family"
          label="Familia"
          class="mt-4 mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.esfamily"
          :rules="rules.esfamily"
          label="Traducció"
          class="mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.position"
          :rules="rules.position"
          label="Ordre"
          single-line
          outlined
        />

      </v-form>
    </edit-dialog>

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from '@/utils';
import AdminContent from '@/components/AdminContent';
import EditDialog from '@/components/Dialog';
import Textfield from '@/components/inputs/Textfield';
import Numberfield from '@/components/inputs/Numberfield';

export default {
  name: 'FamiliesTable',
  components: { AdminContent, EditDialog, Textfield, Numberfield },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    model: {},
    rules: {
      family: [ required('Introdueix un nom per la familia.') ]
    },
    headers: [
      { text: 'Familia',   value: 'family'   },
      { text: 'Traducció', value: 'esfamily' },
      { text: 'Ordre',     value: 'position'    }
    ]
  }),
  computed: mapState( 'api', [ 'families' ]),
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    ...mapMutations( 'api', [ 'setSearch' ]),
    onSearch( search ) {
      this.setSearch({ type: 'families', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( 'api/getFamilies', {
        replace: true,
        page
      });
    },
    editItem( model ) {
      this.model = { position: 0, ...model };
      this.dialogTitle = model ? 'Editar familia' : 'Nova familia';
      this.$refs.form && this.$refs.form.resetValidation();
      this.dialog = true;
    },
    deleteItem( model ) {
      this.$store.dispatch( 'api/removeFamily', model.id ).then(() => {
        this.setAlert({ type: 'success', message: 'Familia eliminada correctament.' });
      }).then(() => {
        this.$store.commit( 'setHome', false );
        this.$store.dispatch( 'loadHome' );
      }).catch( err => {
        console.error( err.message );
        this.setAlert({ type: 'error', message: err.message });
      });
    },
    submit() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.$store.dispatch( 'api/insertFamily', this.sanitize( this.model )).then(() => {
          this.setAlert({ type: 'success', message: 'Dades guardades correctament.' });
        }).then(() => {
          this.$store.commit( 'setHome', false );
          this.$store.dispatch( 'loadHome' );
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    },
    sanitize( model ) {
      for ( var key in model )
        key !== 'position' && ( model[key] = model[key] || '' );
      return model;
    }
  },
  mounted() {
    this.goTo();
  }
}
</script>
