<template>
  <admin-content
    title="Disposicions"
    :headers="headers"
    :items="dispositions.items"
    :num-pages="dispositions.pages"
    :page="dispositions.page"
    :search="dispositions.search"
    @page="goTo"
    @search="onSearch"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="removeItem"
    hide-filters
  >
    <template v-slot:item.date_publication="{ item }">
      {{ formatDate(item.date_publication) }}
    </template>

    <template v-slot:item.notready_disposition="{ item }">
      {{ dispositionStatus( item.notready_disposition ) }}
    </template>

    <dispositions-dialog
      ref="dialog"
      v-model="dialog"
      :title="dialogTitle"
      :data="model"
    />

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { formatDate } from "@/utils";
import AdminContent from "@/components/AdminContent";
import DispositionsDialog from "@/components/DispositionsDialog";

export default {
  name: "DispositionsTable",
  components: { AdminContent, DispositionsDialog },
  data: () => ({
    dialog: false,
    dialogTitle: "Editar disposició",
    model: {},
    search: '',
    statuses: { 0: "Publicada", 1: "Despublicada" },
    headers: [
      { text: "Data", value: "date_publication" },
      { text: "Disposició", value: "disposition" },
      { text: "Estat de publicació", value: "notready_disposition" },
    ],
  }),
  computed: mapState( "api", [ "dispositions" ]),
  methods: {
    formatDate,
    ...mapMutations( 'api', [ 'setSearch' ]),
    onSearch( search ) {
      this.setSearch({ type: 'dispositions', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( "api/getDispositions", {
        replace: true,
        page
      });
    },
    editItem( model ) {
      this.model = {
        date_publication: new Date(),
        notready_disposition: 1,
        ...model
      };
      this.dialogTitle = model ? "Editar disposició" : "Nova disposició";
      this.$refs.dialog && this.$refs.dialog.resetValidation();
      this.dialog = true;
    },
    removeItem( model ) {
      this.$refs.dialog && this.$refs.dialog.remove( model );
    },
    dispositionStatus(value) {
      return this.statuses[value];
    },
  },
  mounted() {
    this.goTo();
  },
};
</script>
