<template>
  <admin-content
    title="Publicacions"
    :headers="headers"
    :items="publications.items"
    :num-pages="publications.pages"
    :page="publications.page"
    :search="publications.search"
    @page="goTo"
    @search="onSearch"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="deleteItem"
    hide-filters
  >

    <edit-dialog
      v-model="dialog"
      :title="dialogTitle"
      @click:save="submit"
    >
      <v-form
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <textfield
          v-model="model.shorttitle"
          :rules="rules.shorttitle"
          label="Abreviació"
          class="mt-4 mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.title"
          :rules="rules.title"
          label="Títol"
          class="mt-4 mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.estitle"
          :rules="rules.estitle"
          label="Traducció"
          class="mb-5"
          single-line
          outlined
        />

      </v-form>
    </edit-dialog>

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from '@/utils';
import AdminContent from '@/components/AdminContent';
import EditDialog from '@/components/Dialog';
import Textfield from '@/components/inputs/Textfield';

export default {
  name: 'PublicationsTable',
  components: { AdminContent, EditDialog, Textfield },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    model: {},
    rules: {
      shorttitle: [ required( 'Introdueix el nom abreviat de la publicació.') ],
      title: [ required( 'Introdueix el nom de la publicació.' ) ]
    },
    headers: [
      { text: 'Abreviació', value: 'shorttitle' },
      { text: 'Títol',      value: 'title'      },
      { text: 'Traducció',  value: 'estitle'    }
    ]
  }),
  computed: mapState( 'api', [ 'publications' ]),
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    ...mapMutations( 'api', [ 'setSearch' ]),
    onSearch( search ) {
      this.setSearch({ type: 'publications', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( 'api/getPublications', {
        replace: true,
        page
      });
    },
    editItem( model ) {
      this.model = { ...model };
      this.dialogTitle = model ? 'Editar publicació' : 'Nova publicació';
      this.$refs.form && this.$refs.form.resetValidation();
      this.dialog = true;
    },
    deleteItem( model ) {
      this.$store.dispatch( 'api/removePublication', model.id ).then(() => {
        this.setAlert({ type: 'success', message: 'Publicació eliminada correctament.' });
      }).then(() => {
        this.$store.commit( 'setHome', false );
        this.$store.dispatch( 'loadHome' );
      }).catch( err => {
        console.error( err.message );
        this.setAlert({ type: 'error', message: err.message });
      });
    },
    submit() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.$store.dispatch( 'api/insertPublication', this.sanitize( this.model )).then(() => {
          this.setAlert({ type: 'success', message: 'Dades guardades correctament.' });
        }).then(() => {
          this.$store.commit( 'setHome', false );
          this.$store.dispatch( 'loadHome' );
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    },
    sanitize( model ) {
      for ( var key in model ) model[key] = model[key] || '';
      return model;
    }
  },
  mounted() {
    this.goTo();
  }
}
</script>
