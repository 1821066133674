<template>
  <admin-content
    title="Descriptors"
    :headers="headers"
    :items="descriptors.items"
    :num-pages="descriptors.pages"
    :page="descriptors.page"
    :search="descriptors.search"
    :total-filters="filters"
    @page="goTo"
    @search="onSearch"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="deleteItem"
  >

    <template v-slot:filters>
      <div class="pa-2">

        <p class="caption mb-10">
          Filtrar per:
        </p>

        <select-families
          v-model="filterFamily"
          class="mb-10"
          style="min-width:260px"
          label="Familia"
          placeholder="Filtrar per familia"
          single-line
          hide-details
          clearable
          outlined
        />

        <select-keys
          v-model="filterKey"
          :filter-by-family="filterFamily"
          style="min-width:260px"
          label="Clau"
          placeholder="Filtrar per clau"
          single-line
          hide-details
          clearable
          outlined
        />

      </div>
    </template>

    <template v-slot:item.family="{ item }">
      {{ getFamilyName( item ) }}
    </template>

    <template v-slot:item.id_keys="{ item }">
      {{ getKeyName( item ) }}
    </template>

    <edit-dialog
      v-model="dialog"
      :title="dialogTitle"
      @click:save="submit"
    >
      <v-form
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <select-keys
          v-model="model.id_keys"
          :rules="rules.id_keys"
          label="Clau asociada"
          class="mt-4 mb-5"
          single-line
          clearable
          outlined
         />

        <textfield
          v-model="model.descriptor"
          :rules="rules.descriptor"
          label="Descriptor"
          class="mt-4 mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.esdescriptor"
          :rules="rules.esdescriptor"
          label="Traducció"
          single-line
          outlined
        />

        <textfield
          v-model="model.clau_aux"
          :rules="rules.clau_aux"
          label="Clau auxiliar"
          single-line
          outlined
        />

      </v-form>
    </edit-dialog>

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required, toArray } from '@/utils';
import AdminContent from '@/components/AdminContent';
import EditDialog from '@/components/Dialog';
import Textfield from '@/components/inputs/Textfield';
import Alert from '@/components/Alert';
import SelectKeys from '@/components/selectors/Keys';
import SelectFamilies from '@/components/selectors/Families';

export default {
  name: 'DescriptorsTable',
  components: {
    AdminContent,
    EditDialog,
    Textfield,
    Alert,
    SelectKeys,
    SelectFamilies
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    model: {},
    rules: {
      descriptor: [ required( 'Introdueix un nom per el descriptor.') ]
    },
    filters: 0,
    filterFamily: null,
    filterKey: null,
    headers: [
      { text: 'Familia',    value: 'family' },
      { text: 'Clau',       value: 'id_keys' },
      { text: 'Descriptor', value: 'descriptor'   },
      { text: 'Traducció',  value: 'esdescriptor' },
      // { text: 'Clau Auxiliar',  value: 'clau_aux' }
    ]
  }),
  computed: {
    ...mapState( 'api', [ 'descriptors' ]),
    ...mapState( 'filters', [ 'families', 'keys' ]),
    isValidFamily() {
      if ( ! this.filterKey || ! this.filterFamily ) return true;
      const key = this.keys.find( k => k.id === this.filterKey );
      return key ? key.parent === this.filterFamily : true;
    }
  },
  watch: {
    filterFamily( value ) {
      this.filters = this.activeFilters();
      this.goTo(1);
    },
    filterKey( value ) {
      this.filters = this.activeFilters();
      this.isValidFamily && this.goTo(1);
    },
    isValidFamily( value ) {
      if ( ! value ) this.filterKey = null;
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    ...mapMutations( 'api', [ 'setSearch' ]),
    activeFilters() {
      var n = 0;
      this.filterFamily && n++;
      this.filterKey && n++;
      return n;
    },
    onSearch( search ) {
      this.setSearch({ type: 'descriptors', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( 'api/getDescriptors', {
        replace: true,
        families: toArray( this.filterFamily ),
        keys: toArray( this.filterKey ),
        page
      });
    },
    editItem( model ) {
      this.model = { ...model };
      this.dialogTitle = model ? 'Editar descriptor' : 'Nou descriptor';
      this.$refs.form && this.$refs.form.resetValidation();
      this.dialog = true;
    },
    deleteItem( model ) {
      this.$store.dispatch( 'api/removeDescriptor', model.id ).then(() => {
        this.setAlert({ type: 'success', message: 'Descriptor eliminat correctament.' });
      }).then(() => {
        this.$store.commit( 'setHome', false );
        this.$store.dispatch( 'loadHome' );
      }).catch( err => {
        console.error( err.message );
        this.setAlert({ type: 'error', message: err.message });
      });
    },
    submit() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.$store.dispatch( 'api/insertDescriptor', this.sanitize( this.model )).then(() => {
          this.setAlert({ type: 'success', message: 'Dades guardades correctament.' });
        }).then(() => {
          this.$store.commit( 'setHome', false );
          this.$store.dispatch( 'loadHome' );
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    },
    sanitize( model ) {
      for ( var key in model ) {
        if ([ 'id', 'id_keys', 'position' ].includes( key )) continue;
        model[key] = model[key] || '';
      }
      return model;
    },
    getKeyName( item ) {
      var key = this.keys.find( k => k.id === item.id_keys );
      return key && key.text;
    },
    getFamilyName( item ) {
      var key = this.keys.find( k => k.id === item.id_keys );
      var family = key && this.families.find( f => f.id === key.parent );
      return family && family.text;
    }
  },
  created() {
    this.$store.dispatch( 'loadHome' );
  },
  mounted() {
    this.goTo();
  }
}
</script>
