<template>
  <admin-content
    title="Usuaris"
    :headers="headers"
    :items="users.items"
    :num-pages="users.pages"
    :page="users.page"
    :search="users.search"
    @search="onSearch"
    @page="goTo"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="deleteItem"
    hide-filters
  >

    <template v-slot:item.role="{ item }">
      {{ item.role == 'admin' ? "Administrador" : "Operador" }}
    </template>

    <edit-dialog
      v-model="dialog"
      :title="dialogTitle"
      @click:save="saveItem"
    >

      <users-form
        ref="form"
        v-model="model"
        @validated="dialog = false"
      />

    </edit-dialog>

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AdminContent from '@/components/AdminContent';
import UsersForm from '@/components/UsersForm';
import EditDialog from '@/components/Dialog';

export default {
  name: 'UsersTable',
  components: { AdminContent, UsersForm, EditDialog },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    model: {},
    headers: [
      { text: 'Nom',               value: 'firstname' },
      { text: 'Cognom',            value: 'lastname'  },
      { text: 'Correu electrònic', value: 'email'     },
      { text: 'Rol',               value: 'role'      }
    ]
  }),
  computed: mapState( 'api', [ 'users' ]),
  watch: {
    search: 'goTo'
  },
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    ...mapMutations( 'api', [ 'setSearch' ]),
    onSearch( search ) {
      this.setSearch({ type: 'users', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( 'api/getUsers', {
        replace: true,
        page
      });
    },
    editItem( model ) {
      this.model = { role: 'admin', sendmail: 1, ...model };
      this.$refs.form && this.$refs.form.resetValidation();
      this.dialogTitle = model ? 'Editar usuari' : 'Nou usuari';
      this.dialog = true;
    },
    saveItem() {
      this.$refs.form && this.$refs.form.submit();
    },
    deleteItem( model ) {
      UsersForm.methods.remove.call( this, model );
    }
  },
  mounted() {
    this.goTo();
  }
}
</script>
