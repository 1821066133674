<template>
  <v-form
    ref="form"
    class="pa-4"
    @submit.prevent="submit"
  >

    <selectfield
      v-if="role == 'admin'"
      :value="model.role"
      :items="roles"
      @input="onInput('role',$event)"
      label="Tipus"
      outlined
    />

    <textfield
      :value="model.firstname"
      :rules="rules.firstname"
      @input="onInput('firstname',$event)"
      label="Nom"
      autocomplete="given-name"
      single-line
      outlined
    />

    <textfield
      :value="model.lastname"
      :rules="rules.lastname"
      @input="onInput('lastname',$event)"
      label="Cognoms"
      autocomplete="family-name"
      single-line
      outlined
    />

    <textfield
      :value="model.email"
      :rules="rules.email"
      @input="onInput('email',$event)"
      label="Email"
      autocomplete="email"
      single-line
      outlined
    />

    <textfield
      :value="model.password"
      :rules="rules.password"
      @input="onInput('password',$event)"
      label="Contrasenya (Només introduïr en cas de modificació o creació)"
      autocomplete="new-password"
      type="password"
      single-line
      outlined
    />

    <textfield
      v-show="model.password"
      v-model="password2"
      :rules="rules.password2"
      autocomplete="confirm-password"
      label="Comprovació de contrasenya"
      type="password"
      single-line
      outlined
    />

  </v-form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from '@/utils';
import Textfield from '@/components/inputs/Textfield';
import Selectfield from '@/components/inputs/Selectfield';

export default {
  components: { Textfield, Selectfield },
  props: {
    value: Object
  },
  data() {
    return {
      model: { ...this.value, password: '' },
      alert: { show: false, type: 'error', message: '' },
      password2: '',
      roles: [
        { value: "admin", text: "Administrador" },
        { value: "user",  text: "Operador" }
      ],
      rules: {
        firstname: [
          required('Introdueix el teu nom')
        ],
        lastname: [
          required('Introdueix el teu cognom')
        ],
        email: [
          required('Introdueix el teu correu electrònic')
        ],
        password2: [
          v => !this.model.password ? true : this.model.password === v || 'La contrasenya no coincideix.'
        ]
      }
    }
  },
  computed: mapState( 'auth', [ 'role' ]),
  watch: {
    value( value ) {
      this.model = { ...value };
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    onInput( key, value ) {
      this.model[key] = value;
      this.$emit( 'input', { ...this.model });
    },
    submit() {
      return new Promise(( resolve, reject ) => {

        if ( ! this.$refs.form.validate()) return resolve( this.model );
        this.$emit('validated');

        this.$store.dispatch( 'api/insertUser', this.sanitize( this.model ))
          .then( res => {
            this.setAlert({ type: 'success', message: 'Dades guardades correctament' });
            resolve( res );
          })
          .catch( err => {
            console.error( err.message );
            this.setAlert({ type: 'error', message: err.message });
            reject( err );
          });
      });
    },
    remove( model ) {
      model = model || this.model;
      if ( model.id > 0 ) {
        this.$store.dispatch( 'api/removeUser', model.id ).then(() => {
          this.setAlert({ type: 'success', message: 'Usuario eliminat correctament' });
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    },
    resetValidation() {
      this.$refs.form && this.$refs.form.resetValidation();
    },
    sanitize( model ) {
      ['firstname','lastname','email'].forEach( key => {
        model[key] = model[key] || '';
      });
      return model;
    }
  }
}
</script>
