<template>
  <admin-content
    ref="content"
    title="Perfil"
    hide-search
  >
    <template v-slot:content>

      <users-form
        ref="form"
        :value="user"
        @input="model = $event"
      />

    </template>
    <template v-slot:footer>

      <v-spacer/>

      <btn color="primary" :loading="loading" @click="save" small>
        Guardar
      </btn>

    </template>

  </admin-content>
</template>

<script>
import { mapState } from 'vuex';
import AdminContent from '@/components/AdminContent';
import UsersForm from '@/components/UsersForm';
import Btn from '@/components/Btn';

export default {
  name: 'Profile',
  components: { AdminContent, UsersForm, Btn },
  data: () => ({
    model: {}
  }),
  computed: {
    ...mapState( 'auth', [ 'user' ]),
    ...mapState( 'api', [ 'loading' ]),
  },
  methods: {
    save() {
      if ( this.$refs.form ) {
        this.$refs.form.submit().then(() => {
          this.$store.commit( 'auth/setUser', {
            ...this.user,
            ...this.model,
            password: ''
          });
        });
      }
    }
  }
}
</script>
