<template>
  <div class="admin">
    <div class="fill-height">

      <v-progress-linear
        class="progress"
        :active="loading"
        :style="`top:${headerHeight}px`"
        color="primary"
        indeterminate absolute
      />

      <div
        v-if="loading"
        class="app-block"
        :style="`top:${headerHeight}px`"
      />

      <v-navigation-drawer
        tag="aside"
        :color="mobile ? 'white' : '#f4f4f4'"
        :width="drawerWidth"
        :value="submenu"
        :permanent="!mobile"
        :temporary="mobile"
        @input="setSubmenu"
        clipped app
      >

        <nav v-if="isAdmin" :style="`padding-top:${headerHeight}px`">
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="item in menu"
                :key="item.path"
                :to="item.path"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"/>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </nav>

      </v-navigation-drawer>

      <router-view/>

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Admin',
  data: () => ({
    menu: [
      { text: 'Disposicions', path: '/admin/dispositions' },
      { text: 'Families',     path: '/admin/families' },
      { text: 'Claus',        path: '/admin/keys' },
      { text: 'Descriptors',  path: '/admin/descriptors' },
      { text: 'Publicacions', path: '/admin/publications' },
      { text: 'Estats',       path: '/admin/states' },
      { text: 'Tipus',        path: '/admin/types' },
      { text: 'Usuaris',      path: '/admin/users' }
    ]
  }),
  computed: {
    ...mapState( 'auth', [ 'userID']),
    ...mapState( 'app', [ 'submenu', 'headerHeight', 'subHeaderHeight', 'drawerWidth' ]),
    ...mapState( 'api', [ 'loading' ]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isAdmin() {
      if ( this.$store.state.auth.user!=null && this.$store.state.auth.user.role != null ) return this.$store.state.auth.user.role == 'admin';
      return false;
    }
  },
  watch: {
    mobile( value ) {
      value && this.setSubmenu( false );
    },
    userID( value ) {
      !value && this.$router.push('/login');
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setSubmenu' ])
  }
}
</script>
