<template>
  <v-main
    id="main"
    class="fill-height"
    :style="`padding-top:${headerHeight}px`"
  >

    <v-toolbar
      class="table-header"
      color="white"
      :style="toolbarStyle"
      :height="subHeaderHeight"
      elevation="0"
    >

      <template v-if="mobile">

        <btn
          class="mr-2"
          :color="submenu ? 'primary' : 'dark'"
          :title="submenu ? 'Tanca el menu' : 'Obre el menu'"
          @click="setSubmenu(!submenu)"
          icon
        >
          <v-icon v-text="submenu ? 'mdi-menu-open':'mdi-menu'"/>
        </btn>

        <h3 class="subtitle-1">{{ title }}</h3>

      </template>

      <slot name="toolbar"/>

      <template v-if="!hideSearch">

        <v-spacer/>

        <v-btn
          v-if="!hideFilters"
          class="mr-5"
          color="primary"
          elevation="0"
          @click="filters = true"
          small
        >
          <v-icon left v-text="'mdi-filter'"/>
          Filtres <span v-if="totalFilters > 0">({{ totalFilters }})</span>
        </v-btn>

        <textfield
          v-model="lazySearch"
          class="mb-0"
          style="width:100%;max-width:250px"
          :placeholder="searchPlaceholder"
          append-icon="mdi-magnify"
          hide-details
          clearable
          outlined
        />

      </template>

    </v-toolbar>
    <div :style="contentStyle">

      <slot name="content">

        <div class="users-table fill-height pa-4">
          <v-card ref="table" class="fill-height pa-4">

            <data-table
              :items="items"
              :headers="computedHeaders"
              :fixed-header="headerHeight + subHeaderHeight"
              :fixed-offset="16"
            >

              <template v-for="name in columns" v-slot:[name]="scopedData">
                <slot :name="name" v-bind="scopedData"/>
              </template>

              <template v-slot:item.actions="scope">
                <slot name="action" v-bind="scope">
                  <btn
                    class="btn-action"
                    :class="{ show: scope.hover }"
                    @click="$emit( 'click:edit', scope.item )"
                    color="primary"
                    small
                  >
                    Editar
                  </btn>
                  <btn
                    class="btn-action"
                    :class="{ show: scope.hover }"
                    @click="onDelete( scope.item )"
                    color="primary"
                    small
                  >
                    Eliminar
                  </btn>
                </slot>
              </template>

            </data-table>
          </v-card>
        </div>
      </slot>
    </div>

    <v-navigation-drawer
      v-if="!hideFilters"
      v-model="filters"
      tag="aside"
      color="white"
      width="300"
      temporary right
      clipped app
    >
      <slot name="filters"/>
    </v-navigation-drawer>

    <slot/>

    <v-toolbar
      class="table-footer"
      color="white"
      elevation="0"
      :height="footerHeight"
      :style="footerStyle"
    >

      <slot name="footer">

        <v-pagination
          v-if="numPages > 1"
          class="pagination"
          v-model="currentPage"
          :length="numPages"
          :total-visible="5"
        />

        <v-spacer/>

        <btn color="primary" @click="$emit('click:new')" small>
          Nou
        </btn>

      </slot>

    </v-toolbar>

    <edit-dialog
      v-model="deleteDialog"
      accept-text="Si"
      cancel-text="No"
      max-width="340"
      hide-button-close
      persistent
      title="Alerta d'eliminació de contingut"
      @click:save="acceptDelete"
    >

      <div class="black--text pa-4">
        <p>Al eliminar un element s'esborra de la base de dades de tal manera que no pot ser recuperable.</p>
        <p ><strong>Està segur que dessitja continuar?</strong></p>
      </div>

    </edit-dialog>

  </v-main>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EditDialog from '@/components/Dialog';
import DataTable from '@/components/Table';
import Textfield from '@/components/inputs/Textfield';
import Btn from '@/components/Btn';

export default {
  components: { EditDialog, DataTable, Textfield, Btn },
  props: {
    title: String,
    page: {
      type: Number,
      default: 1
    },
    numPages: {
      type: Number,
      default: 0
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    hideSearch: Boolean,
    hideFilters: Boolean,
    totalFilters: {
      type: Number,
      default: 0
    },
    search: {
      type: String,
      default: ''
    },
    searchPlaceholder: {
      type: String,
      default: 'Buscar...'
    }
  },
  data() {
    return {
      currentPage: this.page,
      deleteDialog: false,
      selectedItem: null,
      lazySearch: this.search,
      filters: false
    }
  },
  computed: {
    ...mapState( 'app', [
      'submenu',
      'headerHeight',
      'subHeaderHeight',
      'drawerWidth',
      'footerHeight'
    ]),
    computedHeaders() {
      return this.headers.concat([
        { align: 'right', value: 'actions', width: 160 }
      ])
    },
    columns() {
      return this.headers
        .filter( h => h.value !== 'actions' )
        .map( h => `item.${h.value}`);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    contentStyle() {
      return {
        paddingTop: this.subHeaderHeight + 'px',
        paddingBottom: this.footerHeight + 'px'
      }
    },
    toolbarStyle() {
      return {
        top: this.headerHeight + 'px',
        left: this.mobile ? '0px' : this.drawerWidth + 'px'
      }
    },
    footerStyle() {
      return {
        left: this.mobile ? '0px' : this.drawerWidth + 'px'
      }
    }
  },
  watch: {
    page( value ) {
      this.currentPage = value;
    },
    currentPage( value ) {
      this.$emit( 'page', value );
    },
    search( value ) {
      this.lazySearch = value;
    },
    lazySearch( value ) {
      clearInterval( this.interval );
      this.interval = setTimeout(() => this.$emit( 'search', value ), 1000 );
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setSubmenu' ]),
    onDelete( item ) {
      //Confirmation y emit
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    acceptDelete() {
      this.deleteDialog = false;
      this.$emit( 'click:delete', this.selectedItem );
    }
  }
}
</script>

<style>
.table-header {
  position: fixed;
  right: 0;
  z-index: 1;
}
.table-header > div {
  border-bottom: 1px solid #f4f4f4;
}
.table-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.table-footer > div {
  border-top: 1px solid #f4f4f4;
}
.btn-action {
  opacity: 0;
  transition: none;
  margin-left: 2px;
}
.btn-action.show {
  opacity: 1;
}
.pagination .v-pagination__item,
.pagination .v-pagination__navigation {
  font-size: 10px;
  min-width: 24px;
  height: 24px;
  box-shadow: none;
}
.pagination .v-pagination__item {
  border: 1px solid #f4f4f4;
}
.pagination .v-pagination__navigation > i {
  font-size: 20px;
  color: var(--v-primary-base);
}
.pagination .v-pagination__navigation--disabled > i {
  color: #CCC;
}
</style>
