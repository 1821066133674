<template>
  <textfield
    ref="field"
    type="text"
    class="numberfield"
    v-bind="$attrs"
    v-on="listeners"
    @keydown="onKeyDown"
    :value="display"
    :color="color"
    :prevent-key="preventKey"
  >

    <template v-for="name in slots" v-slot:[name]>
      <slot :name="name"/>
    </template>

    <template v-slot:append="props">
      <div v-if="isFocused" class="input__controls">
        <v-btn @click="sum" elevation="0" icon>
          <v-icon v-text="plusIcon"/>
        </v-btn>
        <v-btn @click="less" elevation="0" icon>
          <v-icon v-text="minusIcon"/>
        </v-btn>
      </div>
    </template>

  </textfield>
</template>

<script>
import Textfield from './Textfield';
import { slots, createEvents, countChars } from './utils';
import { keyCodes } from 'vuetify/lib/util/helpers';

const KEY_CODES = Object.values( keyCodes );

export default {
  inheritAttrs: false,
  components: { Textfield },
  props: {
    value: [ Number, String ],
    color: {
      type: String,
      default: 'primary'
    },
    integer: Boolean,
    miller: Boolean,
    positive: Boolean,
    negative: {
      type: Boolean,
      default: true
    },
    millerSeparator: {
      type: String,
      default: ','
    },
    decimalSeparator: {
      type: String,
      default: '.'
    },
    min: [ Number, String ],
    max: [ Number, String ],
    step: {
      type: [ Number, String ],
      default: 1
    },
    plusIcon: {
      type: String,
      default: 'mdi-plus'
    },
    minusIcon: {
      type: String,
      default: 'mdi-minus'
    }
  },
  data() {
    return {
      slots,
      display: undefined,
      internalValue: undefined,
      decimalKey: false,
      isFocused: false
    }
  },
  computed: {
    listeners() {
      return createEvents( this, {
        input: this.onInput,
        focused: this.onFocused
      });
    },
    canNegative() {
      return this.positive !== true && this.negative !== false;
    },
    computedMin() {
      var min = parseFloat( this.min );
      return isNaN( min ) ? -Infinity : min;
    },
    computedMax() {
      var max = parseFloat( this.max );
      return isNaN( max ) ? Infinity : max;
    }
  },
  watch: {
    value: 'setValue'
  },
  methods: {
    computeValue( value ) {
      value = Math.max( Math.min( parseFloat( this.parseValue( value )), this.computedMax ), this.computedMin );
      if ( value < 0 && ! this.canNegative ) value = 0;
      return isNaN( value ) ? undefined : value;
    },
    setValue( value ) {
      if ( ! value && value !== 0 ) {
        this.internalValue = undefined;
        this.display = undefined;
      } else if ( ! this.decimalKey ) {
        this.internalValue = this.computeValue( value );
        this.display = this.parseDisplay( this.internalValue ) || undefined;
      }
      this.decimalKey = false;
    },
    parseValue( value ) {

      if ( ! value && value !== 0 ) return '';
      else if ( typeof value === 'number' ) value = String( value );
      else if ( typeof value === 'string' ) {
        value = value
          .replaceAll( this.millerSeparator, '' )
          .replace( this.decimalSeparator, '.' )
      }

      if ( ! this.canNegative )
        value.replace( '-', '' );

      if ( this.integer ) {
        value = parseInt( value );
        return isNaN( value ) ? '' : value.toString();
      }

      return value;
    },
    parseDisplay( value ) {
      const dec = this.decimalSeparator || '';
      var [ integer, decimal ] = this.parseValue( value ).split('.');
      return this.parseMiller( integer ) + ( decimal ? dec + decimal : '' );
    },
    parseMiller( str ) {
      const mil = this.millerSeparator  || '';
      if ( this.miller && mil ) {
        var millers = [];
        while ( str.length ) {
          millers.unshift( str.slice(-3));
          str = str.slice( 0, -3 );
        }
        str = millers.join( mil );
      }
      return str;
    },
    onInput( value ) {
      this.setValue( value );
      this.$emit( 'input', this.internalValue );
    },
    onKeyDown( event ) {
      if ( event.keyCode === keyCodes.up ) {
        this.sum( event );
      } else if ( event.keyCode === keyCodes.down ) {
        this.less( event );
      }
    },
    onFocused( value ) {
      this.isFocused = value;
    },
    preventKey( event ) {

      const value = ( event.target && event.target.value ) || '';
      if ( ! isNaN( parseInt( event.key ))) return false;

      // Negative
      if ( this.canNegative && event.key === '-' && value.charAt(0) !== '-' )
        return countChars( value, '-' ) > 0;

      // Decimal
      if ( ! this.integer && this.decimalSeparator === event.key ) {
        this.decimalKey = true;
        return countChars( value, this.decimalSeparator ) > 0;
      }

      if ( event.ctrlKey ) return false;
      return ! KEY_CODES.includes( event.keyCode );
    },
    getStep( event ) {
      var step = parseFloat( this.step );
      if ( step < 0 ) step = 1;
      if ( event.shiftKey ) step *= 10;
      if ( event.ctrlKey ) step *= 10;
      return step;
    },
    sum( event ) {
      event.preventDefault();
      const step = this.getStep( event );
      this.setValue(( this.internalValue || 0 ) + step );
    },
    less( event ) {
      event.preventDefault();
      const step = this.getStep( event );
      this.setValue(( this.internalValue || 0 ) - step );
    }
  },
  beforeMount() {
    this.setValue( this.value );
  }
};
</script>

<style>
.numberfield .input__controls {
  line-height: 0;
}
.numberfield .input__controls .v-btn {
  width: 1.5em;
  height: 1.5em;
  color: inherit;
}
.numberfield .input__controls .v-icon {
  font-size: 1em;
}
</style>
