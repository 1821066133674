<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
    outlined
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <btn v-bind="attrs" :color="color" @click="show = false" icon>
        <v-icon>$close</v-icon>
      </btn>
    </template>
  </v-snackbar>
</template>

<script>
import Btn from "@/components/Btn";

export default {
  components: { Btn },
  props: {
    value: null,
    message: String,
    color: {
      type: String,
      default: 'error'
    },
    timeout: {
      type: [ String, Number ],
      default: 5000
    }
  },
  data() {
    return {
      show: !!this.value
    }
  },
  watch: {
    value( value ) {
      this.show = !!value;
    },
    show( value ) {
      this.$emit( 'input', value );
    }
  }
}
</script>

<style>
.login-content {
  height: 100%;
}
.login-body {
  height: 100%;
  padding: 8px;
}
</style>
