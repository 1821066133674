<template>
  <admin-content
    title="Tipus"
    :headers="headers"
    :items="types.items"
    :num-pages="types.pages"
    :page="types.page"
    :search="types.search"
    @page="goTo"
    @search="onSearch"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="deleteItem"
    hide-filters
  >

    <edit-dialog
      v-model="dialog"
      :title="dialogTitle"
      @click:save="submit"
    >
      <v-form
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <textfield
          v-model="model.typedoc"
          :rules="rules.typedoc"
          label="Nom"
          class="mt-4 mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.estypedoc"
          :rules="rules.estypedoc"
          label="Traducció"
          class="mb-5"
          single-line
          outlined
        />

      </v-form>
    </edit-dialog>

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from '@/utils';
import AdminContent from '@/components/AdminContent';
import EditDialog from '@/components/Dialog';
import Textfield from '@/components/inputs/Textfield';

export default {
  name: 'TypesTable',
  components: { AdminContent, EditDialog, Textfield },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    model: {},
    rules: {
      typedoc: [ required('Introdueix un nom per el tipus.') ]
    },
    headers: [
      { text: 'Nom',       value: 'typedoc'   },
      { text: 'Traducció', value: 'estypedoc' }
    ]
  }),
  computed: mapState( 'api', [ 'types' ]),
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    ...mapMutations( 'api', [ 'setSearch' ]),
    onSearch( search ) {
      this.setSearch({ type: 'types', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( 'api/getTypes', {
        replace: true,
        page
      });
    },
    editItem( model ) {
      this.model = { ...model };
      this.dialogTitle = model ? 'Editar tipus' : 'Nou tipus';
      this.$refs.form && this.$refs.form.resetValidation();
      this.dialog = true;
    },
    deleteItem( model ) {
      this.$store.dispatch( 'api/removeType', model.id ).then(() => {
        this.setAlert({ type: 'success', message: 'Tipus eliminat correctament.' });
      }).then(() => {
        this.$store.commit( 'setHome', false );
        this.$store.dispatch( 'loadHome' );
      }).catch( err => {
        console.error( err.message );
        this.setAlert({ type: 'error', message: err.message });
      });
    },
    submit() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.$store.dispatch( 'api/insertType', this.sanitize( this.model )).then(() => {
          this.setAlert({ type: 'success', message: 'Dades guardades correctament.' });
        }).then(() => {
          this.$store.commit( 'setHome', false );
          this.$store.dispatch( 'loadHome' );
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    },
    sanitize( model ) {
      for ( var key in model ) model[key] = model[key] || '';
      return model;
    }
  },
  mounted() {
    this.goTo();
  }
}
</script>
