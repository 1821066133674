<template>
  <selectfield
    v-bind="$attrs"
    v-on="$listeners"
    :items="computedItems"
    :attach="false"
    item-value="id"
  >

    <template v-slot:selection="{ value }">
      <span v-if="value">
        <strong>{{ value.item.text }}</strong>
      </span>
    </template>

    <template v-slot:item="{ item }">
      <span class="caption">
        <strong>{{ item.text }}</strong>
      </span>
    </template>

  </selectfield>
</template>

<script>
import { mapState } from 'vuex';
import Selectfield from '@/components/inputs/Selectfield';

export default {
  components: { Selectfield },
  props: {
    filterByFamily: Number
  },
  computed: {
    ...mapState( 'filters', [ 'families', 'keys' ]),
    computedItems() {
      return this.filterByFamily
        ? this.keys.filter( k => k.parent === this.filterByFamily )
        : this.keys;
    }
  },
  created() {
    this.$store.dispatch('loadHome');
  }
}
</script>
