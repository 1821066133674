<template>
  <admin-content
    title="Claus"
    :headers="headers"
    :items="keys.items || []"
    :num-pages="keys.pages"
    :page="keys.page"
    :search="keys.search"
    :total-filters="filters"
    @page="goTo"
    @search="onSearch"
    @click:edit="editItem"
    @click:new="editItem()"
    @click:delete="deleteItem"
  >

    <template v-slot:filters>
      <div class="pa-2">

        <p class="caption mb-10">
          Filtrar per:
        </p>

        <select-family
          v-model="filterFamily"
          class="mr-2"
          style="min-width:260px"
          label="Familia"
          placeholder="Filtrar per familia"
          single-line
          hide-details
          clearable
          outlined
        />

      </div>
    </template>

    <template v-slot:item.id_families="{ item }">
      {{ getFamilyName( item ) }}
    </template>

    <edit-dialog
      v-model="dialog"
      :title="dialogTitle"
      @click:save="submit"
    >
      <v-form
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <select-family
          v-model="model.id_families"
          :rules="rules.id_families"
          label="Familia"
          class="mt-4 mb-5"
          single-line
          clearable
          outlined
         />

        <textfield
          v-model="model.keyword"
          :rules="rules.keyword"
          label="Clau"
          class="mt-4 mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.eskeyword"
          :rules="rules.eskeyword"
          label="Traducció"
          class="mb-5"
          single-line
          outlined
        />

        <textfield
          v-model="model.position"
          :rules="rules.position"
          label="Ordre"
          class="mb-5"
          single-line
          outlined
        />

      </v-form>
    </edit-dialog>

  </admin-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required, toArray } from '@/utils';
import AdminContent from '@/components/AdminContent';
import EditDialog from '@/components/Dialog';
import Textfield from '@/components/inputs/Textfield';
import Numberfield from '@/components/inputs/Numberfield';
import SelectFamily from '@/components/selectors/Families';

export default {
  name: 'KeysTable',
  components: { AdminContent, EditDialog, Textfield, Numberfield, SelectFamily },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    filters: 0,
    filterFamily: null,
    model: {},
    rules: {
      id_families: [ required('Introdueix la familia de la clau.') ],
      keyword: [ required('Introdueix un nom per la clau.') ]
    },
    headers: [
      { text: 'Familia',      value: 'id_families' },
      { text: 'Clau',         value: 'keyword'   },
      { text: 'Traducció',    value: 'eskeyword' },
      { text: 'Ordre',        value: 'position'  }
    ]
  }),
  computed: {
    ...mapState( 'api', [ 'keys' ]),
    ...mapState( 'filters', [ 'families' ])
  },
  watch: {
    filterFamily( value ) {
      if ( value ) this.filters++;
      else this.filters--;
      this.goTo(1);
    },
  },
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    ...mapMutations( 'api', [ 'setSearch' ]),
    onSearch( search ) {
      this.setSearch({ type: 'keys', search });
      this.goTo();
    },
    goTo( page ) {
      this.$store.dispatch( 'api/getKeys', {
        replace: true,
        families: toArray( this.filterFamily ),
        page
      });
    },
    getFamilyName( item ) {
      var family = this.families.find( f => f.id === item.id_families );
      return family && family.text;
    },
    editItem( model ) {
      this.model = { position: 0, ...model };
      this.dialogTitle = model ? 'Editar clau' : 'Nova clau';
      this.$refs.form && this.$refs.form.resetValidation();
      this.dialog = true;
    },
    deleteItem( model ) {
      this.$store.dispatch( 'api/removeKey', model.id ).then(() => {
        this.setAlert({ type: 'success', message: 'Clau eliminada correctament.' });
      }).then(() => {
        this.$store.commit( 'setHome', false );
        this.$store.dispatch( 'loadHome' );
      }).catch( err => {
        console.error( err.message );
        this.setAlert({ type: 'error', message: err.message });
      });
    },
    submit() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.$store.dispatch( 'api/insertKey', this.sanitize( this.model )).then(() => {
          this.setAlert({ type: 'success', message: 'Dades guardades correctament.' });
        }).then(() => {
          this.$store.commit( 'setHome', false );
          this.$store.dispatch( 'loadHome' );
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    },
    sanitize( model ) {
      for ( var key in model )
        key !== 'position' && ( model[key] = model[key] || '' );
      return model;
    }
  },
  created() {
    this.$store.dispatch( 'loadHome' );
  },
  mounted() {
    this.goTo();
  }
}
</script>
