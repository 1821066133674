<template>
  <v-simple-table
    ref="table"
    class="data-table"
    v-resize="getDimensions"
    :style="tableStyle"
  >
    <template v-slot:default>
      <thead
        ref="head"
        v-bind="headerStyle"
      >
        <tr>
          <th
            v-for="( header, index ) in headers"
            :key="header.value || index"
            :class="`text-${ header.align || 'left' }`"
            :style="isFixed ? columnsWidth[index] : null"
          >
            <slot :name="`header.${header.value}`" v-bind="header">
              {{ header.text || '&nbsp;' }}
            </slot>
          </th>
        </tr>
      </thead>
      <tbody ref="body">
        <v-hover v-for="( item, index ) in items" :key="index">
          <template v-slot="{ hover }">
            <tr :key="item.id || index">
              <td
                v-for="( header, index ) in headers"
                :key="header.value || index"
                :class="`text-${ header.align || 'left' }`"
              >
                <span class="data-table-cell" :style="header.width != null ? { width: header.width + 'px' } : null">
                  <slot :name="`item.${header.value}`" v-bind="{ hover, item, header, value: item[header.value] }">
                    {{ item[header.value] != null ? item[header.value] : '&nbsp;' }}
                  </slot>
                </span>
              </td>
            </tr>
          </template>
        </v-hover>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import colorable from 'vuetify/lib/mixins/colorable';
import { toArray } from '@/utils';

export default {
  mixins: [ colorable ],
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    color: String,
    scrollParent: String,
    fixedHeader: [ Number, Boolean ],
    fixedOffset: {
      type: Number,
      default: 0
    },
    fixedZIndex: {
      type: [ Number, String ],
      default: 1
    }
  },
  data: () => ({
    scroller: null,
    scrollTop: 0,
    offsetTop: 0,
    boundingTable: null,
    headerHeight: null,
    columnsWidth: []
  }),
  computed: {
    fixedTop() {
      return typeof this.fixedHeader === 'number' ? this.fixedHeader : 0;
    },
    isFixed() {
      return this.scrollTop > this.fixedOffset;
    },
    tableStyle() {
      if ( this.isFixed ) {
        return {
          paddingTop: this.headerHeight + 'px'
        }
      }
      return null;
    },
    headerStyle() {
      const data = this.setTextColor( this.color || 'primary', { class: { '--is-fixed': this.isFixed }});
      data.style = { ...data.style, ...this.fixedStyle };
      return data;
    },
    fixedStyle() {
      const { boundingTable } = this;
      if ( this.isFixed && boundingTable ) {
        return {
          top: this.fixedTop + 'px',
          left: boundingTable.left + 'px',
          width: boundingTable.width + 'px',
          zIndex: this.fixedZIndex
        }
      }
      return null;
    }
  },
  watch: {
    fixedHeader( value ) {
      if ( value ) this.enableFixedHeader();
      else this.disableFixedHeader();
    },
    scrollParent( value ) {
      if ( this.fixedHeader ) this.enableFixedHeader();
      else this.disableFixedHeader();
    },
    items() {
      if ( this.fixedHeader )
        requestAnimationFrame( this.enableFixedHeader );
    },
    headers() {
      if ( this.fixedHeader )
        requestAnimationFrame( this.enableFixedHeader );
    }
  },
  methods: {
    getScrollParent( node ) {
      if ( node == null ) return null;
      if ( node.scrollHeight > node.clientHeight ) return node;
      else return this.getScrollParent( node.parentNode );
    },
    getDimensions() {
      const { table, head, body } = this.$refs;
      if ( table && header && body && body.children[0] ) {
        this.boundingTable = table.$el.getBoundingClientRect();
        this.headerHeight = head.clientHeight;
        this.columnsWidth = toArray( body.children[0].querySelectorAll('td')).map( td => {
          return 'flex: 1 1 ' + ( 100 * ( td.clientWidth / this.boundingTable.width )) + '%';
        });
      }
    },
    enableFixedHeader() {

      this.getDimensions();

      // Get first scroll parent
      var node = this.scrollParent
        ? document.querySelector( this.scrollParent )
        : this.getScrollParent( this.$refs.table && this.$refs.table.$el );

      // Disable old scroll parent if exists
      this.disableFixedHeader();

      // Apply event
      this.scroller = node;
      if ( node === document.documentElement ) {
        window.addEventListener( 'scroll', this.onScroll );
      } else {
        node && node.addEventListener( 'scroll', this.onScroll );
      }

      // Run onScroll to get current scrollTop
      this.onScroll();
    },
    disableFixedHeader( node ) {
      node = node || this.scroller;
      node && node.removeEventListener( 'scroll', this.onScroll );
      this.scroller = null;
    },
    onScroll() {
      this.scrollTop = this.scroller ? this.scroller.scrollTop : 0;
    }
  },
  mounted() {
    this.onScroll = this.onScroll.bind( this );
    if ( this.fixedHeader ) requestAnimationFrame( this.enableFixedHeader );
  },
  beforeDestroy() {
    this.disableFixedHeader();
  }
}
</script>

<style>
  .data-table thead th {
    color: inherit !important;
  }
  .data-table td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .data-table thead.--is-fixed {
    position: fixed;
    display: block;
    background-color: white;
  }
  .data-table thead.--is-fixed > tr {
    display: flex;
  }
  .data-table thead.--is-fixed > tr > th {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
  .data-table-cell {
    overflow: hidden;
    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>
